import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/img6.jpg"
import NoImage from "../../../images/no-image.png"
import Slider from "react-slick"
import "./OurAdvantage.scss"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
import { useWindowSize } from "../../../hooks/window-size"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import ShowMoreText from "react-show-more-text"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import Content from "../../Content/Content"
import he from "he"
const OurAdvantage = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        staticExploreTeamDetail(publicationState: LIVE) {
          Team_Detail_Explore {
            Title
            Explore_Tails {
              Title
              all_menus {
                URL
                Page {
                  Pagename
                  Banner_Title
                  Banner_Content
                  Banner_Image {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const MotionCol = motion(Col)
  const MotionRow = motion(Row)
  const [windowWidth] = useWindowSize()

  const teamData = data?.glstrapi?.staticExploreTeamDetail?.Team_Detail_Explore

  var slider_settings = props.static
    ? teamData?.Explore_Tails
    : props.Explore_Tails

  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          infinite: slider_settings?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          infinite: slider_settings?.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          infinite: slider_settings?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: slider_settings?.length > 1 ? true : false,
        },
      },
    ],
  }

  return props.static ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="our-advantage-block"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col lg="12">
                {teamData?.Title && (
                  <motion.h2 variants={contentItem}>
                    {teamData?.Title}
                  </motion.h2>
                )}
              </Col>
            </Row>
            {windowWidth < 1199 && (
              <div>
                <Slider {...settings}>
                  {teamData?.Explore_Tails?.map((item, index) => {
                    var strip_tags =
                      item.all_menus?.Page?.Banner_Content.replace(
                        /<[^>]+>/g,
                        " "
                      ).replace(/(<br \/>\s*)+$/g, " ")
                    var decodedStripedHtml = strip_tags && he.decode(strip_tags)
                    return (
                      <motion.div
                        key={index}
                        custom={index}
                        variants={delayItemsFromBottom}
                        className="advantage-item"
                      >
                        {item.all_menus.Page?.Banner_Image?.url ? (
                          <picture>
                            <GetURL
                              class="tail-link-absolute"
                              alias={item.all_menus?.URL}
                            />
                            <img
                              src={item.all_menus.Page?.Banner_Image?.url}
                              alt={
                                item.all_menus?.Page?.Banner_Image
                                  ?.alternativeText
                                  ? item.all_menus?.Page?.Banner_Image
                                      ?.alternativeText + " - DNG Estate Agents"
                                  : item.Title + " - DNG Estate Agents"
                              }
                            />
                          </picture>
                        ) : (
                          <picture>
                            <img src={NoImage} alt="No Image" />
                          </picture>
                        )}
                        <div className="advantage-info">
                          <h3>
                            <GetURL
                              alias={item.all_menus?.URL}
                              label={item.Title}
                            ></GetURL>
                          </h3>
                          <div className="content-css">
                            {item?.all_menus?.Page?.Banner_Content && (
                              <ShowMoreText
                                /* Default options */
                                lines={4}
                                more="Show more"
                                less="Show less"
                                className=""
                                anchorClass="my-anchor-css-class"
                                // onClick={executeOnClick}
                                expanded={false}
                                // width={280}
                                truncatedEndingComponent={"... "}
                              >
                                {decodedStripedHtml}
                              </ShowMoreText>
                            )}
                          </div>
                          <GetURL
                            label="+ Find out more"
                            alias={item.all_menus?.URL}
                          />
                        </div>
                      </motion.div>
                    )
                  })}
                </Slider>
              </div>
            )}
            {windowWidth > 1199 && (
              <div>
                <Slider {...settings}>
                  {teamData?.Explore_Tails?.map((item, index) => {
                    var strip_tags =
                      item.all_menus?.Page?.Banner_Content.replace(
                        /<[^>]+>/g,
                        " "
                      ).replace(/(<br \/>\s*)+$/g, " ")
                    var decodedStripedHtml = strip_tags && he.decode(strip_tags)

                    return (
                      <MotionCol
                        key={index}
                        custom={index}
                        variants={contentItem}
                        md="6"
                        xl="3"
                        className="d-flex mb-space our-explore"
                      >
                        <div className="advantage-item">
                          {item.all_menus.Page?.Banner_Image?.url ? (
                            <picture>
                              <GetURL
                                class="tail-link-absolute"
                                alias={item.all_menus?.URL}
                              />
                              <img
                                src={item.all_menus.Page?.Banner_Image?.url}
                                alt={
                                  item.all_menus?.Page?.Banner_Image
                                    ?.alternativeText
                                    ? item.all_menus?.Page?.Banner_Image
                                        ?.alternativeText +
                                      " - DNG Estate Agents"
                                    : item.Title + " - DNG Estate Agents"
                                }
                              />
                            </picture>
                          ) : (
                            <picture>
                              <img src={NoImage} alt="No Image" />
                            </picture>
                          )}
                          <div className="advantage-info related-services">
                            <h3>
                              <GetURL
                                alias={item.all_menus?.URL}
                                label={item.Title}
                              ></GetURL>
                            </h3>

                            <div className="content-css">
                              {item?.all_menus?.Page?.Banner_Content && (
                                <ShowMoreText
                                  /* Default options */
                                  lines={4}
                                  more="Show more"
                                  less="Show less"
                                  className=""
                                  anchorClass="my-anchor-css-class"
                                  // onClick={executeOnClick}
                                  expanded={false}
                                  // width={280}
                                  truncatedEndingComponent={"... "}
                                >
                                  {decodedStripedHtml}
                                </ShowMoreText>
                              )}
                            </div>
                            <GetURL
                              label="+ Find out more"
                              alias={item.all_menus?.URL}
                              class="mt-2 display-inline-block"
                            />
                          </div>
                        </div>
                      </MotionCol>
                    )
                  })}
                </Slider>
              </div>
            )}
          </Container>
        </motion.div>
      )}
    </InView>
  ) : (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="our-advantage-block"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col lg="12">
                {props.Title && (
                  <motion.h2 variants={contentItem}>{props.Title}</motion.h2>
                )}
              </Col>
            </Row>
            {windowWidth < 1199 && (
              <div>
                <Slider {...settings}>
                  {props.Explore_Tails?.map((item, index) => {
                    var strip_tags =
                      item.all_menus?.Page?.Banner_Content.replace(
                        /<[^>]+>/g,
                        " "
                      ).replace(/(<br \/>\s*)+$/g, " ")
                    var decodedStripedHtml = strip_tags && he.decode(strip_tags)

                    const description = item.Description?.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )

                    return (
                      <motion.div
                        key={index}
                        custom={index}
                        variants={delayItemsFromBottom}
                        className="advantage-item"
                      >
                        {item.all_menus?.Page?.Banner_Image?.url ? (
                          <picture>
                            <GetURL
                              class="tail-link-absolute"
                              alias={item.all_menus?.URL}
                            />
                            <img
                              src={item.all_menus?.Page?.Banner_Image?.url}
                              alt={
                                item.all_menus?.Page?.Banner_Image
                                  ?.alternativeText
                                  ? item.all_menus?.Page?.Banner_Image
                                      ?.alternativeText + " - DNG Estate Agents"
                                  : item.Title + " - DNG Estate Agents"
                              }
                            />
                          </picture>
                        ) : item.Image ? (
                          <picture>
                            <GetURL
                              alias={item.all_menus?.URL}
                              class="tail-link-absolute"
                            ></GetURL>
                            <img
                              src={item.Image?.url}
                              alt={item.Title + "DNG Estate Agents"}
                            />
                          </picture>
                        ) : props.Image?.url ? (
                          <picture>
                            <GetURL
                              class="tail-link-absolute"
                              alias={item.all_menus?.URL}
                            />
                            <img
                              src={props.Image?.url}
                              alt={item.Title + "DNG Estate Agents"}
                            />
                          </picture>
                        ) : (
                          <picture>
                            <img src={NoImage} alt="No Image" />
                          </picture>
                        )}
                        <div className="advantage-info">
                          <h3>
                            <GetURL
                              alias={item.all_menus?.URL}
                              label={item.Title}
                            ></GetURL>
                          </h3>

                          <div className="content-css">
                            {(item?.all_menus?.Page?.Banner_Content ||
                              description) && (
                              <ShowMoreText
                                /* Default options */
                                lines={4}
                                more="Show more"
                                less="Show less"
                                className=""
                                anchorClass="my-anchor-css-class"
                                // onClick={executeOnClick}
                                expanded={false}
                                // width={280}
                                truncatedEndingComponent={"... "}
                              >
                                {decodedStripedHtml
                                  ? decodedStripedHtml
                                  : description}
                              </ShowMoreText>
                            )}
                          </div>
                          <GetURL
                            label="+ Find out more"
                            alias={item.all_menus?.URL}
                            class="mt-2 display-inline-block"
                          />
                        </div>
                      </motion.div>
                    )
                  })}
                </Slider>
              </div>
            )}
            {windowWidth > 1199 && (
              <div>
                <Slider {...settings}>
                  {props.Explore_Tails?.map((item, index) => {
                    var strip_tags =
                      item.all_menus?.Page?.Banner_Content?.replace(
                        /<[^>]+>/g,
                        " "
                      ).replace(/(<br \/>\s*)+$/g, " ")
                    var decodedStripedHtml = strip_tags && he.decode(strip_tags)

                    const description = item.Description?.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )

                    return (
                      <MotionCol
                        key={index}
                        custom={index}
                        variants={contentItem}
                        md="6"
                        xl="3"
                        className="d-flex mb-space our-explore"
                      >
                        <div className="advantage-item">
                          {item.all_menus?.Page?.Banner_Image?.url ? (
                            <picture>
                              <GetURL
                                alias={item.all_menus?.URL}
                                class="tail-link-absolute"
                              ></GetURL>
                              <img
                                src={item.all_menus?.Page?.Banner_Image?.url}
                                alt={
                                  item.all_menus?.Page?.Banner_Image
                                    ?.alternativeText
                                    ? item.all_menus?.Page?.Banner_Image
                                        ?.alternativeText +
                                      " - DNG Estate Agents"
                                    : item.Title + " - DNG Estate Agents"
                                }
                              />
                            </picture>
                          ) : item.Image ? (
                            <picture>
                              <GetURL
                                alias={item.all_menus?.URL}
                                class="tail-link-absolute"
                              ></GetURL>
                              <img
                                src={item.Image?.url}
                                alt={item.Title + "DNG Estate Agents"}
                              />
                            </picture>
                          ) : (
                            <picture>
                              <GetURL
                                alias={item.all_menus?.URL}
                                class="tail-link-absolute"
                              ></GetURL>

                              <img src={NoImage} alt="No Image" />
                            </picture>
                          )}

                          <div className="advantage-info related-services">
                            <h3>
                              <GetURL
                                alias={item.all_menus?.URL}
                                label={item.Title}
                              ></GetURL>
                            </h3>

                            <div className="content-css">
                              {(item?.all_menus?.Page?.Banner_Content ||
                                description) && (
                                <Content
                                  Content={
                                    decodedStripedHtml
                                      ? decodedStripedHtml
                                      : description
                                  }
                                />
                                // <ShowMoreText
                                //   /* Default options */
                                //   lines={3}
                                //   more="Show more"
                                //   less="Show less"
                                //   className=""
                                //   anchorClass="my-anchor-css-class"
                                //   // onClick={executeOnClick}
                                //   expanded={false}
                                //   // width={280}
                                //   truncatedEndingComponent={"... "}
                                // >
                                //   {decodedStripedHtml
                                //     ? decodedStripedHtml
                                //     : description}
                                // </ShowMoreText>
                              )}
                            </div>
                            
                            <div className="find-more-link">
                            <GetURL
                              label="+ Find out more"
                              alias={item.all_menus?.URL}
                              class="mt-2 display-inline-block"
                            />
                            </div>
                          </div>
                        </div>
                      </MotionCol>
                    )
                  })}
                </Slider>
              </div>
            )}
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default OurAdvantage
